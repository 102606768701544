/**
* Replace placeholders in string with corresponding keys from Object o
* @example supplant(
*   'Hello {place}! I\'m {name}',
*   {place: 'World', name: 'Pete'
* }) // => 'Hello World! I'm Pete'
*
* @param {stringInput} string String to format
* @param {Object} o Object with keys mapping to placeholders in string
*/
const supplant = (stringInput, o) => {
	return stringInput.replace(/{([^{}]*)}/g,
		function (a, b) {
			var r = o[b];

			return typeof r === 'string' || typeof r === 'number' ? r : a;
		}
	);
};

export { supplant };