/**
 * Carousel
 */

import Component from '../../js/core/component.js';
import scrollTo from '../../js/utils/scroll-to/u-scroll-to.js';
import keycodes from '../../js/utils/keycodes/u-keycodes.js';
import focusableElements from '../../js/utils/focusable-elements/u-focusable-elements.js';
import '../../js/polyfills/polyfill-scroll-to.js';
import icon from '../icon/components.icon.vanilla';

class Carousel extends Component {
	/**
	 * Default settings for this component
	 * @return {object} Default settings
	 */
	_defaultSettings() {
		return {
			debug: false,
			selectors: {
				item: '.c-carousel__item',
				container: '.c-carousel__items',
				background: '.c-carousel__background',
			},
			text: {
				prevButton: 'Previous slide',
				nextButton: 'Next slide',
				navigationButton: 'Go to slide {index}'
			},
			classNames: {
				inited: 'is-inited',
				visuallyHidden: 't-visually-hidden',
				wrapper: 'c-carousel__wrapper',
				slide: 'c-carousel__slide',
				item: 'c-carousel__item',
				isActive: 'is-active',
				isSliding: 'is-sliding',
				prevButton: 'c-carousel__prev-button',
				nextButton: 'c-carousel__next-button',
				navigation: 'c-carousel__nav',
				navigationList: 'c-carousel__nav-list',
				navigationItem: 'c-carousel__nav-item',
				navigationButton: 'c-carousel__nav-button'
			},
			itemsPerSlide: 1,
			buttons: true,
			navigation: true,
			on: {
				beforeSlide: () => { },
				slide: () => { }
			}
		};
	}

	/**
	 * Initialize component
	 */
	_init() {
		this.inited = false;
		this.busy = false;
		this.element = this.settings.element;
		this.container = this.element.querySelector(this.settings.selectors.container);
		this.items = this.element.querySelectorAll(this.settings.selectors.item);
	}

	_create() {
		let slidesLength = Math.ceil(this.items.length / this.settings.itemsPerSlide);

		this.wrapper = document.createElement('div');
		this.wrapper.classList.add(this.settings.classNames.wrapper);

		this.container.style.width = `${slidesLength * 100}%`;

		let slideWidth = `${100 / slidesLength}%`;
		this.slides = [];
		let itemCount = 0;

		for (let i = 0; i < slidesLength; i++) {
			let slide = document.createElement('li');
			slide.classList.add(this.settings.classNames.slide);
			slide.setAttribute('aria-hidden', 'true');
			slide.setAttribute('tabindex', '-1');
			slide.style.width = slideWidth;
			this.slides.push(slide);

			let grid = document.createElement('div');
			grid.classList.add('o-grid', 'o-grid--stretched');

			for (let i = 0; i < this.settings.itemsPerSlide; i++) {
				if (this.items[itemCount]) {
					this.items[itemCount].remove();

					let column = document.createElement('div');
					column.classList.add('o-grid__column');
					column.setAttribute('data-size', (24 / this.settings.itemsPerSlide));

					let item = document.createElement('div');
					item.classList.add(this.settings.classNames.item);

					this.items[itemCount].classList.forEach((classItem) => {
						if (classItem !== this.settings.classNames.item) {
							item.classList.add(classItem);
						}
					});

					item.innerHTML = this.items[itemCount].innerHTML;

					if (this.items[itemCount].querySelector(this.settings.selectors.background).getAttribute('style')) {
						item.setAttribute('style', this.items[itemCount].querySelector(this.settings.selectors.background).getAttribute('style'));
					}

					if (this.items[itemCount].getAttribute('data-title')) {
						item.setAttribute('data-title', this.items[itemCount].getAttribute('data-title'));
					}

					column.appendChild(item);
					grid.appendChild(column);
				}
				else {
					break;
				}

				slide.appendChild(grid);

				slide.querySelectorAll(focusableElements).forEach((element) => {
					element.setAttribute('tabindex', '-1');
				});

				itemCount++;
			}

			this.container.appendChild(slide);
		}

		this.wrapper.appendChild(this.container);
		this.element.appendChild(this.wrapper);

		this.liveRegion = document.createElement('div');
		this.liveRegion.setAttribute('aria-live', 'polite');
		this.liveRegion.setAttribute('aria-atomic', 'true');
		this.liveRegion.classList.add(this.settings.classNames.visuallyHidden);

		this.element.appendChild(this.liveRegion);
	}

	_createNavigationButtons() {
		this.prevButton = document.createElement('button');
		this.prevButton.setAttribute('type', 'button');
		this.prevButton.classList.add(this.settings.classNames.prevButton);

		let prevText = document.createElement('span');
		prevText.classList.add('t-visually-hidden');
		prevText.innerText = 'Föregående bild';

		this.prevButton.appendChild(prevText);

		let prevContent = icon({
			name: 'untailed-arrow-left',
			size: '48x48'
		});

		this.prevButton.appendChild(prevContent);

		this.prevButton.addEventListener('click', this.prevSlide.bind(this));

		this.nextButton = document.createElement('button');
		this.nextButton.setAttribute('type', 'button');
		this.nextButton.classList.add(this.settings.classNames.nextButton);

		let nextText = document.createElement('span');
		nextText.classList.add('t-visually-hidden');
		nextText.innerText = 'Nästa bild';

		this.nextButton.appendChild(nextText);

		let nextContent = icon({
			name: 'untailed-arrow-right',
			size: '48x48'
		});
		this.nextButton.appendChild(nextContent);

		this.nextButton.addEventListener('click', this.nextSlide.bind(this));

		this.element.appendChild(this.prevButton);
        this.element.appendChild(this.nextButton);
	}

	_createNavigation() {
		this.nav = document.createElement('div');
		this.nav.classList.add(this.settings.classNames.navigation);

		this.element.appendChild(this.nav);

		this.navList = document.createElement('ul');
		this.navList.classList.add(this.settings.classNames.navigationList);

		this.nav.appendChild(this.navList);

		this.navButtons = [];

		this.slides.forEach((slide, i) => {
			let item = document.createElement('li');
			item.classList.add(this.settings.classNames.navigationItem);

			let button = document.createElement('button');
			button.classList.add(this.settings.classNames.navigationButton);
			button.setAttribute('type', 'button');
			button.setAttribute('data-slide', i);
			button.appendChild(document.createTextNode(this.settings.text.navigationButton.replace('{index}', (i + 1))));

			button.addEventListener('click', () => {
				this.gotoSlide(button.getAttribute('data-slide'));
			});

			this.navButtons.push(button);

			item.appendChild(button);

			this.navList.appendChild(item);
		});
	}

	_getPrevSlideIndex() {
		let prevItem = this.slides[this.current - 1];

		if (prevItem) {
			return this.current - 1;
		}

		return this.slides.length - 1;
	}

	_getNextSlideIndex() {
		let nextItem = this.slides[this.current + 1];

		if (nextItem) {
			return this.current + 1;
		}

		return 0;
	}

	_getCurrentSlideFromPosition() {
		this._log('_getCurrentSlideFromPosition()');

		let index = false;

		this.slides.forEach((slide, i) => {
            let scrollMIddle = this.wrapper.scrollLeft + (this.wrapper.clientWidth / 2);

			if ((slide.offsetLeft === this.wrapper.scrollLeft && i !== this.current) || (slide.offsetLeft < scrollMIddle && (slide.offsetLeft + slide.clientWidth) > scrollMIddle && slide.offsetLeft !== this.wrapper.scrollLeft)) {
				index = i;
			}
		});

		return index;
	}

	_setCarouselHeight() {
		this._log('_setCarouselHeight()');
		let height = this._hook('carouselHeight', this.slides[this.current].clientHeight);
		this.element.style.height = `${height}px`;
	}

	_updateCurrentSlide(index) {
		this._log('_updateCurrentSlide()');

		this.current = parseInt(index);

		this.settings.on.beforeSlide(this);

		this.slides.forEach((slide) => {
			slide.setAttribute('aria-hidden', 'true');
			slide.classList.remove(this.settings.classNames.isActive);
		});
		this.slides[this.current].removeAttribute('aria-hidden');
		this.slides[this.current].classList.add(this.settings.classNames.isActive);

		if (this.nav) {
			this.nav.querySelectorAll('[aria-current]').forEach((button) => {
				button.removeAttribute('aria-current');
			});

			this.navButtons[index].setAttribute('aria-current', 'true');
		}

		this.liveRegion.textContent = 'Slide ' + (parseFloat(this.current) + 1) + ' of ' + this.slides.length;

		this._setCarouselHeight();

		this.settings.on.slide(this);
	}

	_keyPressHandler(e) {
		switch (e.keyCode) {
			case keycodes.LEFT:
				e.preventDefault();
				this.prevSlide();
				break;
			case keycodes.RIGHT:
				e.preventDefault();
				this.nextSlide();
				break;
			case keycodes.HOME:
				e.preventDefault();
				this.firstSlide();
				break;
			case keycodes.END:
				e.preventDefault();
				this.lastSlide();
				break;
			case keycodes.TAB:
				if (e.target !== this.nextButton) {
					this._tabHandler(e);
				}
				break;
		}
	}

	_getFirstFocusableElementInSlide(slideIndex) {
		return this.slides[slideIndex].querySelector(focusableElements);
	}

	_getLastFocusableElementInSlide(slideIndex) {
		const elements = this.slides[slideIndex].querySelectorAll(focusableElements);
		return elements[elements.length - 1];
	}

	_tabHandler(e) {
		if (e.shiftKey === false) {
			if (document.activeElement === this.slides[this.current] && e.shiftKey === false) {
				const firstFocusableElement = this._getFirstFocusableElementInSlide(this.current);

				if (firstFocusableElement) {
					e.preventDefault();
					firstFocusableElement.focus();
				}
				return;
			}

			const lastFocusableElement = this._getLastFocusableElementInSlide(this.current);

			if (this.prevButton && document.activeElement === lastFocusableElement && (this.slides.length - 1 !== this.current)) {
				e.preventDefault();
				this.prevButton.focus();
			}
		}
		else {
			if (this.prevButton && document.activeElement === this.prevButton) {
				const lastFocusableElement = this._getLastFocusableElementInSlide(this.current);

				if (lastFocusableElement) {
					e.preventDefault();
					lastFocusableElement.focus();
				}
				return;
			}
		}
	}

	/* =============================================================================
	 * Public methods
	============================================================================= */
	init() {
		this._log('init()');

		this._create();
		if (this.settings.buttons) {
			this._createNavigationButtons();
		}

		if (this.settings.navigation) {
			this._createNavigation();
		}

		this.gotoSlide(0, false);

        let timer = null;
            
		this.wrapper.addEventListener('scroll', () => {
			if (timer !== null) {
				clearTimeout(timer);
			}

			if (this.busy) {
				return;
			}

			timer = setTimeout(() => {
				let index = this._getCurrentSlideFromPosition();

				if (index !== false) {
					this.gotoSlide(index);
				}
			}, 150);
		});

		this.element.classList.add(this.settings.classNames.inited);
		this.inited = true;

        this.element.addEventListener('keydown', this._keyPressHandler.bind(this));
    }

	destroy() {
		this._log('destroy()');

		if (this.inited !== true) {
			return;
		}

		this.slides.forEach((slide) => {
			slide.remove();
		});

		this.items.forEach((item) => {
			item.style.width = null;
			this.element.appendChild(item);
		});

		if (this.prevButton) {
			this.prevButton.remove();
		}

		if (this.nextButton) {
			this.nextButton.remove();
		}

		if (this.nav) {
			this.nav.remove();
		}

		this.container.remove();
		this.wrapper.remove();

		this.element.style.height = null;
		this.element.classList.remove(this.settings.classNames.inited);

		this.inited = false;
	}

	prevSlide() {
		this._log('prevSlide()');
		this.gotoSlide(this._getPrevSlideIndex());
	}

	nextSlide() {
		this._log('nextSlide()');
		this.gotoSlide(this._getNextSlideIndex());
	}

	firstSlide() {
		this._log('firstSlide()');
		this.gotoSlide(0);
	}

	lastSlide() {
		this._log('lastSlide()');
		this.gotoSlide(this.slides.length - 1);
    }

	sameSlide() {
        this._log('sameSlide()');
        var slideNumber = this._getCurrentSlideFromPosition();
        if (slideNumber !== false) {
            this.gotoSlide(slideNumber);
        } 
    }
    
	async gotoSlide(index, focus = true) {
                
		this.busy = true;
		this.element.classList.add(this.settings.classNames.isSliding);

		return new Promise(async (resolve) => {

			this._log(`gotoSlide(${index})`);

			let slide = this.slides[index];

			this.container.querySelectorAll(focusableElements).forEach((element) => {
				element.setAttribute('tabindex', '-1');
			});

			slide.querySelectorAll(focusableElements).forEach((element) => {
				element.removeAttribute('tabindex');
			});

			this._updateCurrentSlide(index);

			// Scroll smooooth to current slide
			await scrollTo({
				element: this.wrapper,
				x: slide.offsetLeft + parseFloat(window.getComputedStyle(slide, null).getPropertyValue('padding-left')),
				duration: 600
			});

			this.element.classList.remove(this.settings.classNames.isSliding);

			// Set focus to current slide
			if (focus) {
				this.slides[this.current].focus();
			}

			setTimeout(() => {
				this.busy = false;
				resolve();
			}, 50);

		});
	}

    
}

export default Carousel;
