import extend from '../utils/extend/u-extend.js';

export default class Component {
	constructor (settings = {}) {
		this.inited = false;
		this.settings = extend(true, this._defaultSettings(), settings);

		if (this.settings.manualInit !== true) {
			this._init();
		}
	}
	_defaultSettings () {
		return {
			debug: false,
			manualInit: true
		};
	}
	_init () {}
	_log (message = '') {
		if (!this.settings.debug) {
			return;
		}
		console.log(message);
	}
	_hook (name, value) {
		if (this.settings.hooks && typeof this.settings.hooks[name] === 'function') {
			return this.settings.hooks[name](this, value);
		}
		return value;
	}
}
