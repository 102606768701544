import Carousel from './components/carousel/components.carousel.js';
import Accordion from './components/accordion/components.accordion.js';
import getDataSettings from './js/utils/u-get-data-settings.js';
import breakpoint from './js/utils/breakpoint/u-breakpoint.js';

document.addEventListener('newcontent', () => {
	document.querySelectorAll('.c-carousel').forEach((carousel) => {
		let settings = getDataSettings(carousel, {
			debug: false,
			element: carousel
		});

		carousel.carousel = new Carousel(settings);
		breakpoint('only screen and (min-width:768px)', {
			first_enter: function() {},
			enter: function() {
				carousel.carousel.destroy();
				carousel.carousel.settings.itemsPerSlide = 1;
				carousel.carousel.init();
			},
			exit: function() {}
		});
		breakpoint('only screen and (max-width:767px)', {
			first_enter: function() {},
			enter: function() {
				carousel.carousel.destroy();
				carousel.carousel.settings.itemsPerSlide = 1;
				carousel.carousel.init();
			},
			exit: function() {}
        });
        
        window.addEventListener('resize', function(event) {
            carousel.carousel.sameSlide();
        });
    });    
});
document.dispatchEvent(new CustomEvent('newcontent'));

// Init accordions
Accordion(document.querySelectorAll('[data-module="accordion"]'));
