import isIterable from '../is-iterable/u-is-iterable.js';

/**
Generate a random id for the element(s)
@param {Array} [elements] List of dom-elements to apply random ids on
@param {Boolean} [overwrite] Whether to overwrite an exisiting id
*/
const randomId = function (elements, overwrite = false, prefix = 'id') {
	if (!isIterable(elements)) {
		elements = [elements];
	}
	prefix = typeof prefix === 'string' ? prefix : '';
	elements.forEach(function (el) {
		let id;
		if (overwrite || !el.getAttribute('id')) {
			// Loop just to be sure the id doesn't already exist in the DOM
			do {
				id = prefix + Math.random().toString().replace(/\D/, '');
			} while (document.querySelectorAll('#' + id).length);
			el.setAttribute('id', id);
		}
	});

	return elements;
};

export default randomId;
