import extend from '../extend/u-extend.js';
import easings from '../easings/u-easings.js';

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
var requestAnimFrame = (function(){
	return  window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function( callback ){ window.setTimeout(callback, 1000 / 60); };
})();

export default function (settings = {}) {
	settings = extend({
		element: null,
		x: 0,
		y: 0,
		duration: 200,
		easing: 'easeInOutQuad',
		increment: 20
	}, settings);

	let element = settings.element;
	let currentTime = 0;
	let start = position();
	let changeLeft = settings.x - start.x;
	let changeTop = settings.y - start.y;

	function move (x, y) {
		element.scrollLeft = x;
		element.scrollTop = y;
	}

	function position () {
		return {
			x: element.scrollLeft,
			y: element.scrollTop
		};
	}

	return new Promise((resolve) => {
		const animateScroll = () => {
			// Increment the time
			currentTime += settings.increment;

			// Get scroll position
			var valLeft = easings[settings.easing](currentTime, start.x, changeLeft, settings.duration);
			var valTop = easings[settings.easing](currentTime, start.y, changeTop, settings.duration);

			// Move the elements scroll position
			move(valLeft, valTop);

			// Do the animation unless its over
			if (currentTime < settings.duration) {
				requestAnimFrame(animateScroll);
			}
			else {
				resolve();
			}
		};

		animateScroll();
	});
}
