import Component from '../../core/component.js';
import generateRandomId from '../random-id/u-random-id.js';
import icon from '../../../components/icon/components.icon.vanilla.js';
import insertAfterElement from '../insert-after/u-insert-after.js';

class Collapse extends Component {
	/**
	 * Default settings for this component
	 * @return {object} Default settings
	 */
	_defaultSettings () {
		return {
			debug: false,
			element: null,
			expanded: false,
			icons: false, // URLstring or {collapsed: URLstring, expanded: URLstring}
			selectors: {
				header: '> h3',
				content: '> div'
			},
			classNames: {
				expanded: 'is-expanded',
				collapsed: 'is-collapsed',
				button: '',
				buttonText: 't-visually-hidden',
                iconExtraClass: '',
                iconHolderClass: '',
				animationClass: 'b-animation-slide-in'
			},
			strings: {
				show: 'Show content',
				hide: 'Hide content'
			},
			on: {
				beforeInit:     () => {},
				beforeToggle:   () => {},
				beforeExpand:   () => {},
				beforeCollapse: () => {},
				init:           () => {},
				toggle:         () => {},
				expand:         () => {},
				collapse:       () => {}
			}
		};
	}

	/**
	 * Initialize Collapse
	 */
	_init () {
		// Run beforeInit hook
		this.settings.on.beforeInit();

		// Assign main element
		this.element = this.settings.element;

		// Generate element ID if no ID is set
		if (!this.element.id) {
			generateRandomId(this.element, false, 'collapse-');
		}

		// Fetch header and content element from the DOM
		this.header = document.querySelector(`#${this.element.id} ${this.settings.selectors.header}`);
		this.content = document.querySelector(`#${this.element.id} ${this.settings.selectors.content}`);

		// Set element ID for content element if no ID is set
		if (!this.content.id) {
			this.content.id = `${this.element.id}-content`;
		}

		// Create toggle button
		this.button = document.createElement('button');
		this.button.className = this.settings.classNames.button;
		this.button.setAttribute('type', 'button');
		this.button.setAttribute('aria-expanded', true);
		this.button.setAttribute('aria-controls', this.content.id);

		this.buttonText = document.createElement('span');
		this.buttonText.innerHTML = this.header.innerHTML;
		this.button.innerHTML = this.buttonText.outerHTML;

		// Add on click event to toggle button
		this.button.addEventListener('click', this.toggle.bind(this));

		// Set class and aria expanded as collapsed if the content is collapsed as default
		if (this.settings.expanded === false) {
			this.element.classList.add(this.settings.classNames.collapsed);
			this.button.setAttribute('aria-expanded',  false);
		}
		// Set class as expanded
		else {
			this.element.classList.add(this.settings.classNames.expanded);
		}

		// Init button icon
		this._initIcon();
		this._updateIcon();

		// Add hidden show/hide text for the toggle button
		this.buttonAction = document.createElement('span');
		this.buttonAction.classList.add(this.settings.classNames.buttonText);
		this.buttonAction.innerHTML = (this.isExpanded()) ? this.settings.strings.hide : this.settings.strings.show;

		// Add show/hide text to toggle button
		this.button.appendChild(this.buttonAction);

		// Replace header with the new toggle button
		this.header.innerHTML = '';
		this.header.appendChild(this.button);

		// Run init hook
		this.settings.on.init(this);
	}

	/**
	 * Initialize icon URL
	 */
	_initIcon () {
		// If icons setting is a string, then use the string
		if (typeof this.settings.icons === 'string') {
			this.iconURL = this.settings.icons;
		}
		// Else if the icons setting properties collapsed/expanded is set, then use them
		else if (this.settings.icons.collapsed && this.settings.icons.expanded) {
			this.iconURL = {
				collapsed: this.settings.icons.collapsed,
				expanded: this.settings.icons.expanded
			};
		}
		// Otherwise set the icon URL to false
		else {
			this.iconURL = false;
		}
	}

	/**
	 * Get button icon
	 * @return {mixed} [Icon element]
	 */
	_getIcon () {
		// Return false if we don't want icons
		if (!this.iconURL) {
			return false;
		}

		// Get current state
		let state = (this.isExpanded()) ? 'expanded' : 'collapsed';

		// If we allways use the same icon
		if (typeof this.iconURL === 'string') {
			// Return icon element

			return icon({
				name: this.iconURL,
				size: this.settings.icons.size,
				extraClass: this.settings.classNames.iconExtraClass ? this.settings.classNames.iconExtraClass : ''
			});
		}
		// If we want different icons depending on the state
		else if (this.iconURL[state]) {
			// Return icon element for current state
			return icon({
				name: this.iconURL[state],
				size:this.settings.icons.size,
				extraClass: this.settings.classNames.iconExtraClass ? this.settings.classNames.iconExtraClass : ''
			});
		}

		return false;
	}

	_updateIcon () {
		// Check if we need to update the icon, if not exit
		if (!this.iconURL || (this.icon && typeof this.iconURL === 'string')) {
			return;
		}

		// If there is a icon, remove it
		if (this.icon) {
			this.icon.remove();
		}

		// Get the new icon
		this.icon = this._getIcon();

		// Append the icon to toggle button
		if (this.icon) {
			insertAfterElement(this.icon, this.button.firstChild);
        }
        
        // Adds a class to span above icon
        if (this.settings.classNames.iconHolderClass) {
            this.button.childNodes[1].classList.add(this.settings.classNames.iconHolderClass);
        }

	}

	/* =============================================================================
	 * Public methods
	============================================================================= */

	/**
	 * Toggle content
	 */
	toggle () {
		// Run beforeToggle hook
		this.settings.on.beforeToggle(this);

		// If the content is expanded, then collpase the content
		if (this.button.getAttribute('aria-expanded') === 'true') {
			this.collapse();
		}
		// Otherwise expand the content
		else {
			this.expand();
		}

		// Run toggle hook
		this.settings.on.toggle(this);
	}

	/**
	 * Expand content
	 */
	expand () {
		// Run beforeExpand hook
		this.settings.on.beforeExpand(this);

		// Handle CSS animation
		if (this.settings.classNames.animationClass) {
			this.content.classList.add(this.settings.classNames.animationClass);
			this.content.addEventListener('webkitAnimationEnd', () => {
				this.content.classList.remove(this.settings.classNames.animationClass);
			});
			this.content.addEventListener('animationend', () => {
				this.content.classList.remove(this.settings.classNames.animationClass);
			});
		}

		// Set aria-expanded to true
		this.button.setAttribute('aria-expanded', true);

		// Toggle collapse/expanded classes
		this.element.classList.remove(this.settings.classNames.collapsed);
		this.element.classList.add(this.settings.classNames.expanded);

		// Uppdate toggle button hidden text
		this.buttonAction.innerHTML = this.settings.strings.hide;

		// Update toggle button icon
		this._updateIcon();

		// Run expand hook
		this.settings.on.expand(this);
	}

	/**
	 * Collapse content
	 */
	collapse () {
		// Run beforeCollapse hook
		this.settings.on.beforeCollapse(this);

		// Set aria-expanded to false
		this.button.setAttribute('aria-expanded', false);

		// Toggle collapse/expanded classes
		this.element.classList.add(this.settings.classNames.collapsed);
		this.element.classList.remove(this.settings.classNames.expanded);

		// Uppdate toggle button hidden text
		this.buttonAction.innerHTML = this.settings.strings.show;

		// Update toggle button icon
		this._updateIcon();

		// Run collapse hook
		this.settings.on.collapse(this);
	}

	/**
	 * Checkes if content is expanded
	 * @return {Boolean}
	 */
	isExpanded () {
		return this.button.getAttribute('aria-expanded') === 'true';
	}

	/**
	 * Checkes if content is collapsed
	 * @return {Boolean}
	 */
	isCollapsed () {
		return this.button.getAttribute('aria-expanded') !== 'true';
	}
}

export default Collapse;
