/**
 * Accordion
 */

import Collapse from '../../js/utils/collapse/u-collapse.js';
import extend from '../../js/utils/extend/u-extend.js';
import '../../js/polyfills/polyfill-nodelist-foreach.js';

export default function (accordions) {
	accordions.forEach((accordion) => {

		let settings = {
			element: accordion,
			selectors: {
				header: '.c-accordion__trigger',
				content: '.c-accordion__wrapper'
			},
			classNames: {
				expanded: 'c-accordion--is-expanded',
				collapsed: 'c-accordion--is-collapsed',
				button: 'c-accordion__button',
				buttonText: 't-visually-hidden',
                iconExtraClass: 'c-accordion__icon',
                iconHolderClass: 'c-accordion__icon-holder'
			},
			strings: {
				show: 'Visa innehåll',
				hide: 'Dölj innehåll'
			},
			icons: {
				collapsed: 'plus',
                expanded: 'minus',
                size: '16x16'
			}
		};

		if (accordion.getAttribute('data-js-accordion-settings')) {
            settings = extend(true, settings, JSON.parse(accordion.getAttribute('data-js-accordion-settings')));
		}

		new Collapse(settings);

	});
}
