import extend from './extend/u-extend.js';

export default (element, merge = {}) => {

	let settings = {};

	try {
		settings = JSON.parse(element.getAttribute('data-settings').replace(/'/g, '"'));
	}
	catch (e) {
		settings = {};
	}

	return extend(true, settings, merge);
};
