export default {
	TAB:    9,
	ENTER:  13,
	ESCAPE: 27,
	SPACE:  32,
	END:    35,
	HOME:   36,
	LEFT:   37,
	UP:     38,
	RIGHT:  39,
	DOWN:   40
};
