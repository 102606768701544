import { supplant } from '../../js/utils/string.vanilla';

const icon = (settingsInput) => {
	let element = document.createElement('span');
	let defaultSettings = {
		name: 'a-o',
		size: '13x13',
		extraClass: ''
	};

	let settings = {
		name: (settingsInput && settingsInput.name) ? settingsInput.name : defaultSettings.name,
		size: (settingsInput && settingsInput.size) ? settingsInput.size : defaultSettings.size,
		extraClass: (settingsInput && settingsInput.extraClass) ? settingsInput.extraClass : defaultSettings.extraClass
	};

	if (!settings.name.startsWith('icon-')) {
		settings.name = 'icon-' + settings.name;
	}

	element.innerHTML = supplant(
		'<span aria-hidden="true" class="c-icon--{size} {extraClass}">' +
		'<svg><use xlink:href="/gui/_dist/icons.svg#{name}"></use>' +
		'</svg></span>',
		settings
	);
	return element;
};

export default icon;